<template>
  <div>
    <div class="columns mt-4">
      <div class="column">
        <p v-html="$t('message.fields.pension.explanation', { appClient: $appClient })"></p>
        <div class="field mt-5">
          <label class="label"
            >{{ $t('message.fields.pension.stipp') }}</label
          >
          <div class="control">
            <label class="radio">
              <input
                v-model="pension.retirement_plan"
                type="radio"
                :value="1"
              />
              {{ $t('message.options.yes') }}
            </label>
            <label class="radio">
              <input
                v-model="pension.retirement_plan"
                type="radio"
                :value="0"
              />
              {{ $t('message.options.no') }}
            </label>
          </div>
        </div>
      </div>
    </div>

    <div class="columns" v-if="pension.retirement_plan === 1">
      <div class="column is-half">
        <label class="label"
          >{{ $t('message.fields.pension.type') }}</label
        >
        <div class="control">
          <label class="radio">
            <input
              v-model="pension.retirement_plan_type"
              type="radio"
              value="basis"
            />
            {{ $t('message.fields.pension.basis') }}
          </label>
          <label class="radio">
            <input
              v-model="pension.retirement_plan_type"
              type="radio"
              value="plus"
            />
            {{ $t('message.fields.pension.plus') }}
          </label>
        </div>
        
      </div>
    </div>
    <div class="columns" v-if="pension.retirement_plan === 1">
      <div class="column is-half">
        <label class="label">{{ $t('message.fields.pension.overview') }}</label>
        <file-upload 
          :value="pension.retirement_plan_hash" 
          customUrl="https://werken-promopolitan.nl/api/media/media/guest" 
          :customParams="{ hash, checksum, file_type: 'retirement_plan', name: 'Pensioen document' }"
          :preventDeleteFromServer="true"
          paramName="media"
        />
      </div>
    </div>
    <div v-else-if="pension.retirement_plan === 0" class="columns">
      <div class="column">
        <label class="checkbox">
          <input
            v-model="pension.no_stipp"
            type="checkbox"
            :value="1"
          />

          {{ $t('message.fields.pension.declare') }}
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import FileUpload from '../components/FileUpload.vue'

export default {
  components: { FileUpload },
  props: {
    pension: {
      type: Object,
      required: true
    },
    hash: {
      type: String,
      required: true
    },
    checksum: {
      type: String,
      required: true
    }
  }
}
</script>